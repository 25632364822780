// GOOGLE Analytics Dashboard


(function(w,d,s,g,js,fjs){
  g=w.gapi||(w.gapi={});g.analytics={q:[],ready:function(cb){this.q.push(cb)}};
  js=d.createElement(s);fjs=d.getElementsByTagName(s)[0];
  js.src='https://apis.google.com/js/platform.js';
  fjs.parentNode.insertBefore(js,fjs);js.onload=function(){g.load('analytics')};
}(window,document,'script'));

// GOOGLE ANALYTICS CODE

gapi.analytics.ready(function() {

  /**
   * Authorize the user immediately if the user has already granted access.
   * If no access has been created, render an authorize button inside the
   * element with the ID "embed-api-auth-container".
   */
  gapi.analytics.auth.authorize({
    container: 'embed-api-auth-container',
    clientid: '880081712996-2ap1l0m293ikbch8m27ibuqq49innl7s.apps.googleusercontent.com',
  });


  /**
   * Create a ViewSelector for the first view to be rendered inside of an
   * element with the id "view-selector-1-container".
   */
  var viewSelector1 = new gapi.analytics.ViewSelector({
    container: 'view-selector-1-container'
  });

  /**
   * Create a ViewSelector for the second view to be rendered inside of an
   * element with the id "view-selector-2-container".
   */
  var viewSelector2 = new gapi.analytics.ViewSelector({
    container: 'view-selector-2-container'
  });

  var viewSelector3 = new gapi.analytics.ViewSelector({
    container: 'view-selector-3-container'
  });

  // Render both view selectors to the page.
  viewSelector1.execute();
  viewSelector2.execute();
  viewSelector3.execute();


  var googleAnalyticsId = 'ga:119450569';

  /**
   * Create the first DataChart for top countries over the past 30 days.
   * It will be rendered inside an element with the id "chart-1-container".
   */
  var dataChart1 = new gapi.analytics.googleCharts.DataChart({
    query: {
      id: googleAnalyticsId,
      metrics: 'ga:sessions',
      dimensions: 'ga:country',
      'start-date': '30daysAgo',
      'end-date': 'yesterday',
      'max-results': 6,
      sort: '-ga:sessions'
    },
    chart: {
      container: 'chart-1-container',
      type: 'PIE',
      options: {
        width: '80%'
      }
    }
  });

var dataChart2 = new gapi.analytics.googleCharts.DataChart({
   reportType: 'ga',
   query: {
     id: googleAnalyticsId,
     'dimensions': 'ga:date',
     'metrics': 'ga:sessions',
     'start-date': '30daysAgo',
     'end-date': 'yesterday',
   },
   chart: {
     type: 'LINE',
     container: 'chart-2-container',
     options: {
        width: '80%'
      }
   }
 });

var dataChart3 = new gapi.analytics.googleCharts.DataChart({
   reportType: 'ga',
   query: {
     id: googleAnalyticsId,
     'dimensions': 'ga:deviceCategory',
     'metrics': 'ga:sessions',
     'start-date': '30daysAgo',
     'end-date': 'yesterday',
       'max-results': '6',
   },
   chart: {
     type: 'TABLE',
     container: 'chart-3-container',
     options: {
        width: '100%'
      }
   }
 });

  /**
   * Update the first dataChart when the first view selecter is changed.
   */
  viewSelector1.on('change', function(ids) {
    dataChart1.set({query: {ids: googleAnalyticsId}}).execute();
    console.log(ids);
  });

  /**
   * Update the second dataChart when the second view selecter is changed.
   */
  viewSelector2.on('change', function(ids) {
    dataChart2.set({query: {ids: googleAnalyticsId}}).execute();
    console.log(ids);
  });

  viewSelector3.on('change', function(ids) {
    dataChart3.set({query: {ids: googleAnalyticsId}}).execute();
  });









  /**
   * Create a new ViewSelector instance to be rendered ins3ide of an
   * element with the id "view-selector-container".
   */
  var viewSelector = new gapi.analytics.ViewSelector({
    container: 'view-selector-container'
  });

  // Render the view selector to the page.
  viewSelector.execute();

  /**
   * Create a table chart showing top browsers for users to interact with.
   * Clicking on a row in the table will update a second timeline chart with
   * data from the selected browser.
   */
  var mainChart = new gapi.analytics.googleCharts.DataChart({
    query: {
      id: googleAnalyticsId,
      'dimensions': 'ga:browser',
      'metrics': 'ga:sessions',
      'sort': '-ga:sessions',
      'max-results': '6'
    },
    chart: {
      type: 'TABLE',
      container: 'main-chart-container',
      options: {
        width: '100%'
      }
    }
  });


  /**
   * Create a timeline chart showing sessions over time for the browser the
   * user selected in the main chart.
   */
  var breakdownChart = new gapi.analytics.googleCharts.DataChart({
    query: {
      'dimensions': 'ga:date',
      'metrics': 'ga:sessions',
      'start-date': '7daysAgo',
      'end-date': 'yesterday'
    },
    chart: {
      type: 'LINE',
      container: 'breakdown-chart-container',
      options: {
        width: '100%'
      }
    }
  });


  /**
   * Store a refernce to the row click listener variable so it can be
   * removed later to prevent leaking memory when the chart instance is
   * replaced.
   */
  var mainChartRowClickListener;


  /**
   * Update both charts whenever the selected view changes.
   */
  viewSelector.on('change', function(ids) {
    var options = {query: {ids: googleAnalyticsId}};

    // Clean up any event listeners registered on the main chart before
    // rendering a new one.
    if (mainChartRowClickListener) {
      google.visualization.events.removeListener(mainChartRowClickListener);
    }

    mainChart.set(options).execute();
    breakdownChart.set(options);

    // Only render the breakdown chart if a browser filter has been set.
    if (breakdownChart.get().query.filters) breakdownChart.execute();
  });


  /**
   * Each time the main chart is rendered, add an event listener to it so
   * that when the user clicks on a row, the line chart is updated with
   * the data from the browser in the clicked row.
   */
  mainChart.on('success', function(response) {

    var chart = response.chart;
    var dataTable = response.dataTable;

    // Store a reference to this listener so it can be cleaned up later.
    mainChartRowClickListener = google.visualization.events
        .addListener(chart, 'select', function(event) {

      // When you unselect a row, the "select" event still fires
      // but the selection is empty. Ignore that case.
      if (!chart.getSelection().length) return;

      var row =  chart.getSelection()[0].row;
      var browser =  dataTable.getValue(row, 0);
      var options = {
        query: {
          filters: 'ga:browser==' + browser
        },
        chart: {
          options: {
            title: browser
          }
        }
      };

      breakdownChart.set(options).execute();
    });
  });
});